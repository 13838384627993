var headerSwiper = new Swiper('.header-swiper', {
        pagination: '.header-swiper-pagination',
        slidesPerView: 1,
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        loop: true,
        autoplay: 4000,
        autoplayDisableOnInteraction: false,
        effect: 'fade'
    });

var repairSwiper = new Swiper('.repair-swiper', {
    pagination: '.repair-swiper-pagination',
    slidesPerView: 1,
    paginationClickable: true,
    loop: true,
    effect: 'fade',
    paginationBulletRender: function (swiper, index, className) {
        return '<span class="type-' + index + ' ' + className + '"><\/span>';
    }
});